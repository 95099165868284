<template>
  <!-- 管理员列表 -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="管理员列表"
    ></el-page-header>
    <div class="select-container">
      <div class="select">
        <div class="selectVal">
          <div class="text">查找管理员：</div>
          <el-input
            placeholder="请输入管理员名称"
            v-model="inputData"
            clearable
          >
          </el-input>
        </div>
        <el-button
          type="primary"
          class="search"
          icon="el-icon-search"
          :disabled="buttonDisabled"
          @click="searchButton"
          >搜索</el-button
        >
        <el-button type="success" @click="allProduct">全部</el-button>
      </div>
      <div class="product-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          border
          style="width: 100%; max-width: 100%"
          :cell-style="csllStyle"
        >
          <el-table-column
            fixed
            type="index"
            :index="pagesize * page_num - pagesize + 1"
            label="序号"
            width="80%"
          >
          </el-table-column>
          <el-table-column prop="username" label="管理员名称" width="260%">
          </el-table-column>
          <el-table-column prop="cardNo" label="账号" width="280%">
          </el-table-column>
          <el-table-column prop="verify" label="权限" width="210%">
          </el-table-column>
          <el-table-column prop="adminState" label="账号状态" width="200%">
          </el-table-column>
          <el-table-column prop="db" label="创建时间" width="280%">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="220%">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button type="text" @click="compile(scope.row)" size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页底部 -->
        <el-pagination
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      data: "",
      tableData: [],
      value: "title",
      inputData: "",
      buttonDisabled: true,
      // 是否加载数据
      loading: true,
      currentPage: 1,
      pagesize: 10,
      files_count: 5,
      fileList: [],
      searchFile: "",
      totalNum: 0,
      page_num: 1,
    };
  },
  created() {
    let pageNum = 1;
    let list = true;
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    let userKey = this.userKey;
    axios.post(this.GLOBAL.api + "/admin/info", { list, pageNum, userKey }).then((res) => {
      this.loading = false;
      this.totalNum = res.data.total[0].count;
      res.data.val.forEach((data) => {
        this.tableData.push({
          username: data.username,
          cardNo: data.cardNo,
          verify: data.verify === "1" ? "超级管理员" : "普通管理员",
          adminState: data.adminState === "1" ? "正常" : "停用",
          db: data.db,
        });
      });
    });
  },
  // 监听器
  watch: {
    inputData: function () {
      this.buttonDisabled =
        this.inputData !== "" || this.inputData ? false : true;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    handleClick(row) {
      const id = row.cardNo;
      this.$router.push(`/fix/admin/${id}`);
    },
    handleCurrentChange(val) {
      let pageNum = val;
      this.page_num = val;
      let list = true;
      axios.post(this.GLOBAL.api + "/admin/info", { list, pageNum }).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    searchButton() {
      const serch = true;
      const username = this.inputData;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/admin/info", { serch, username,userKey }).then((res) => {
        this.loading = false;
        this.totalNum = res.data.total[0].count;
        this.tableData = [];
        res.data.val.forEach((data) => {
          this.tableData.push({
            username: data.username,
            cardNo: data.cardNo,
            verify: data.verify === "1" ? "超级管理员" : "普通管理员",
            adminState: data.adminState === "1" ? "正常" : "停用",
            db: data.db,
          });
        });
      });
    },
    compile(e) {
      this.$confirm("是否确定删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        const cardNo = e.cardNo;
        const deleteData = true;
        let userKey = this.userKey;
        axios.post(this.GLOBAL.api + "/admin/info", { deleteData, cardNo, userKey }).then((res) => {
          if (res.data.statusCode === 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.reload();
          } else {
            let pageNum = 1;
            let list = true;
            axios.post(this.GLOBAL.api + "/admin/info", { list, pageNum, userKey }).then((res) => {
              this.loading = false;
              this.totalNum = res.data.total[0].count;
              res.data.val.forEach((data) => {
                this.tableData.push({
                  username: data.username,
                  cardNo: data.cardNo,
                  verify: data.verify === "1" ? "超级管理员" : "普通管理员",
                  adminState: data.adminState === "1" ? "正常" : "停用",
                  db: data.db,
                });
              });
            });
          }
        });
      });
    },
    allProduct() {
      let pageNum = 1;
      let list = true;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/admin/info", { list, pageNum, userKey }).then((res) => {
        this.loading = false;
        this.totalNum = res.data.total[0].count;
        this.tableData = [];
        res.data.val.forEach((data) => {
          this.tableData.push({
            username: data.username,
            cardNo: data.cardNo,
            verify: data.verify === "1" ? "超级管理员" : "普通管理员",
            adminState: data.adminState === "1" ? "正常" : "停用",
            db: data.db,
          });
        });
      });
    },
    csllStyle(row) {
      // 监听tabel数据进行操作
      if (row.row.adminState === '停用') {
        return 'backgroundColor:rgb(167 41 41);color:#FFF'
      }
    }
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.select {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.selectVal {
  display: flex;
  width: 35%;
  margin-left: 30px;
}
.product-table {
  width: 100%;
}
.search {
  margin-left: 10px;
}
.text {
  width: 35%;
  height: 100%;
  line-height: 250%;
}
.el-table {
  margin-top: 5%;
}
</style>